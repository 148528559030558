export class Api {
  apiKey;
  baseUrl = 'https://api.interviewcopilot.io';
  // private baseUrl: string = 'http://localhost:4000/api';
  constructor(apiKey) {
    this.apiKey = apiKey;
  }

  createCheckoutSession = (priceLookupKey) => {
    return fetch(`${this.baseUrl}/checkout-sessions`,  {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.apiKey}`
      },
      body: JSON.stringify({priceLookupKey: priceLookupKey})
    })
      .then(response => response.json())
      .catch(err => console.error(err))
  }


  getCurrentUser = () => {
    return fetch(`${this.baseUrl}/users/me`,  {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      }
    })
      .then(response => response.json())
      .catch(err => console.error(err))
  }

  createCustomerPortalSession = () => {
    return fetch(`${this.baseUrl}/customer-portal-sessions`,  {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.apiKey}`
      }
    })
      .then(response => response.json())
      .catch(err => console.error(err))
  }


  // public getSession = () => {
  //   return fetch(`${this.baseUrl}/sessions`,  {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${this.apiKey}`
  //     }
  //   })
  //     .then(response => response.ok ? response.json() : Promise.reject(`error: ${response.status}`))
  //     .catch(err => console.error(err))
  // }
  //
  // public deleteSession = () => {
  //   return fetch(`${this.baseUrl}/sessions`,  {
  //     method: 'DELETE',
  //     headers: {
  //       'Authorization': `Bearer ${this.apiKey}`
  //     }
  //   })
  //     .then(response => response.json())
  //     .catch(err => console.error(err))
  // }
  //
  // public getAnswer = async () => {
  //   try {
  //     return await fetch(`${this.baseUrl}/answers`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${this.apiKey}`
  //       }
  //     })
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }
}
