import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import {Auth0Provider} from "@auth0/auth0-react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import TermsOfService from "./pages/TermsOfService.js";

Modal.setAppElement("#root");

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    element: <TermsOfService />,
  },
]);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Auth0Provider domain="dev-qtzxgo85oj15qzbz.us.auth0.com"
                 clientId="7r8dEHPi0gRHZcMESX73HsnBPbvVDRG3"
                 authorizationParams={{redirect_uri: window.location.origin, audience: "https://icp/api"}}
  cookieDomain=".interviewcopilot.io">
    <RouterProvider router={router} />
  </Auth0Provider>
);
