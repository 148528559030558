import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {LogoLink} from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import logo from "../images/logo.svg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Terms & Conditions" }) => {
  return (
    <AnimationRevealPage>
      <LogoLink href="/" style={{textDecoration: 'none'}}>
        <img src={logo} alt="logo" />
        Interview Copilot
      </LogoLink>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: October 2, 2023</p>

            <p>
              These Terms and Conditions ("Terms") govern your use of the Interview Copilot website, software, services, and other offerings (collectively, "Services"). Please read these Terms carefully before using our Services.
            </p>

            <h2>Acceptance of Terms</h2>
            <p>By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our Services.</p>

            <h2>Registration</h2>
            <p>To use certain features of our Services, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process.</p>

            <h2>Use of Services</h2>
            <p>You agree to use our Services only for purposes that are permitted by these Terms and any applicable law, regulation, or generally accepted practices or guidelines in the relevant jurisdictions.</p>

            <h2>Intellectual Property</h2>
            <p>The Services and its original content, features, and functionality are and will remain the exclusive property of Interview Copilot io.</p>

            <h2>Subscriptions</h2>
            <p>All subscriptions to our Services, including renewals, are non-refundable. Once a subscription fee has been paid, no refunds will be provided, regardless of the reason for cancellation or termination. By purchasing a subscription, you acknowledge and agree to this non-refundability policy.</p>

            <h2>Cancellation Policy</h2>
            <p>
              <strong>Cancellation by the User:</strong> You may cancel your subscription at any time by accessing your account settings on our website or by contacting our support team at support@interviewcopilot.io. Cancellations will take effect at the end of the current billing period. You will continue to have access to the Services until the end of your subscription term. Please note that no refunds or credits will be provided for partial subscription periods, including when the subscription is canceled before the end of a billing period.
            </p>
            <p>
              <strong>Automatic Renewal:</strong> All subscriptions will automatically renew at the end of the subscription term unless canceled by the user. By agreeing to these Terms, you authorize Interview Copilot to charge the applicable subscription fees for the renewal period.
            </p>
            <p>
              <strong>Cancellation by Interview Copilot:</strong> We reserve the right to terminate or suspend your subscription at any time, with or without notice, for conduct that we believe violates these Terms or our policies, or for any other reason in our sole discretion.
            </p>
            <p>
              <strong>Effect of Cancellation:</strong> Upon cancellation, your access to the Services will continue until the end of the current billing cycle, after which your access will be terminated. No refunds will be issued for the remaining period after cancellation.
            </p>
            <p>
              <strong>Non-Refundable Policy:</strong> As outlined in our Subscriptions section, all fees are non-refundable, and there will be no refunds or credits for partially used periods.
            </p>

            <h2>Privacy</h2>
            <p>Your use of our Services is also subject to our Privacy Policy. By using our Services, you acknowledge and agree that you have read and understand our Privacy Policy.</p>

            <h2>Limitation of Liability</h2>
            <p>To the maximum extent permitted by law, Interview Copilot io shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your access to or use of or inability to access or use the Services.</p>

            <h2>Changes to the Terms</h2>
            <p>We may modify these Terms at any time. We will post the most current version of these Terms on our website. By continuing to use our Services after the changes become effective, you agree to be bound by the revised Terms.</p>

            <h2>Termination</h2>
            <p>We reserve the right to terminate or suspend your access to all or part of our Services at any time, with or without cause, with or without notice.</p>

            <h2>Contact Information</h2>
            <p>If you have any questions about these Terms, please contact us at: support@interviewcopilot.io</p>
            <p>By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};