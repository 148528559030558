import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import mainImage from "images/copilot/main.png";
import processImage from "images/copilot/process.png";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import ScrollToHashElement from "components/ScrollToHashElement";
import GlobalStyles from "./styles/GlobalStyles.js";
import {useAuth0} from "@auth0/auth0-react";
import {Api} from "./api/api.js";
import {notification} from "antd";
import DashedBorderSixFeatures from "./components/features/DashedBorderSixFeatures";

export default () => {

  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect, } = useAuth0();
  const [api, setApi] = useState(null)
  const [user, setUser] = useState(null)
  const [apiNotification, contextHolder, ] = notification.useNotification();

  const isPaymentSuccessful = window.location.search.includes('?success=true&session_id=')
  const isPaymentCanceled = window.location.search.includes('?canceled=true')

  if (isPaymentSuccessful) {
    apiNotification.destroy('payment-success')
    apiNotification['success']({
      message: 'Congratulations!',
      description:
        <div>You've successfully subscribed to Interview Copilot. Welcome to a world of interview mastery and success. <br/><br/>
          <strong>What's Next?</strong><br/>

Prepare to elevate your interview game to new heights. With Interview Copilot, you're armed with real-time AI guidance and the tools to ace any interview that comes your way.<br/><br/>

Need assistance or have questions? Our dedicated support team is here to help you on your journey.<br/>

          Thank you for choosing Interview CoPilot. Your path to interview success starts now!</div>,
      duration: 0,
      key: 'payment-success'
    });
  }

  if (isPaymentCanceled) {
    apiNotification.destroy('payment-canceled')
    apiNotification['error']({
      message: 'Payment canceled',
      description:
        'We noticed that your recent payment for Interview Copilot has been canceled. If this was not intentional, please ensure that your payment information is up to date to avoid any interruptions in your subscription.',
      duration: 0,
      key: 'payment-canceled'
    });
  }

  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedText2 = tw.span`text-primary-500`;
  // const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  useEffect(() => {
    window.ym(95114617, 'hit', 'https://interviewcopilot.io/');
  }, [])


  useEffect( () => {
    if (isAuthenticated) {
      (async () => {
        const token = await getAccessTokenSilently()
        const api = new Api(token)
        setApi(api)
        const subItem = localStorage.getItem('subscription')
        if (subItem && subItem !== 'Free') {
          const session = await api.createCheckoutSession(subItem)
          localStorage.removeItem('subscription')
          window.location.replace(session.redirect_url)
        } else if (subItem && subItem === 'Free') {
          localStorage.removeItem('subscription')
          window.location.replace('https://new-app.interviewcopilot.io')
        }

        const user = await api.getCurrentUser()
        if (user) {
          setUser(user)
        }
      })()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  const testimonials = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
      quote:
        "Using Interview Copilot was a game-changer. During a critical interview, I faced a challenging question. With just a tap, the platform provided me with a brilliant response. I got the job – and I couldn't be happier.",
      customerName: "Sarah M."
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
      quote:
        "Interview Copilot turned my job search around. With its real-time responses, I aced tough questions and showcased my skills confidently. Thanks to this platform, I landed a job that aligns perfectly with my goals.",
      customerName: "Dave S."
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
      quote:
        "Interview Copilot's seamless experience made my job search stress-free. Its real-time assistance is unparalleled. I navigated interviews effortlessly, and guess what? I secured my dream role within weeks!",
      customerName: "Alex R."
    }
  ]

  const steps = [
    {
      heading: "Start Your Interview",
      description: "Use your preferred device for recording – whether it's your phone, tablet, or laptop – to record your interview naturally."
    },
    {
      heading: "Tap the Get Answer Button",
      description: "During the interview, if you encounter a challenging question or need assistance, tap the \"Get Answer\" button."
    },
    {
      heading: "Instant AI Guidance",
      description: "Witness the magic as Interview CoPilot reviews your interview on-the-spot and generates the perfect response for you."
    }
  ];


  const handleSubscription = async (priceName) => {
    if (!api) {
      localStorage.setItem('subscription', priceName)
      await loginWithRedirect()
      return
    }
    if (priceName !== 'Free') {
      const session = await api.createCheckoutSession(priceName)
      window.location.replace(session.redirect_url)
    } else {
      window.location.replace('https://new-app.interviewcopilot.io')
    }
  }

  const handleCustomerPortalClick = async () => {
    const portal = await api.createCustomerPortalSession()
    if (portal && portal.redirect_url) {
      window.location.replace(portal.redirect_url)
    }
  }


  return (
    <>
      {contextHolder}
      <GlobalStyles />
    <AnimationRevealPage disabled={true}>
      <Hero
        heading={<>Ace Interviews with <HighlightedText>Interview Copilot</HighlightedText></>}
        description="Interview Copilot is your key to interview success. Experience AI crafting polished responses in real-time, tailored for your live interview. Grab your dream job – start now for free."
        imageSrc={mainImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Get Started for Free"
        watchVideoButtonText="Watch how it works"
        watchVideoYoutubeUrl="https://www.youtube.com/embed/k6FStuGTnag"
        primaryButtonUrl="#pricing"
        displayCustomerPortalLink={user?.customer_id}
        onCustomerPortalClick={handleCustomerPortalClick}
      />
      <ScrollToHashElement/>
      <div id="features"/>
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText2>Get Started.</HighlightedText2>
          </>
        }
        textOnLeft={false}
        imageSrc={processImage}
        imageDecoratorBlob={true}
        steps={steps}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />

      <DashedBorderSixFeatures/>

      {/*<MainFeature*/}
      {/*    subheading={<Subheading>Your Instant Answers During Interviews</Subheading>}*/}
      {/*    heading={*/}
      {/*      <>*/}
      {/*        Seamless Interview Mastery*/}
      {/*      </>*/}
      {/*    }*/}
      {/*    description={*/}
      {/*      <Description>*/}
      {/*        Imagine having an interview expert by your side, analyzing questions, and providing optimal answers, empowering you to confidently navigate interviews and land your dream job.*/}

      {/*      </Description>*/}
      {/*    }*/}
      {/*    buttonRounded={false}*/}
      {/*    textOnLeft={false}*/}
      {/*    imageSrc={*/}
      {/*      mainFeatureImage*/}
      {/*    }*/}
      {/*    imageCss={imageCss}*/}
      {/*    imageDecoratorBlob={true}*/}
      {/*    imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}*/}
      {/*/>*/}


      <div id="testimonials"/>
      <Testimonial
        subheading=""
        heading={<>Real Stories of <HighlightedText>Success</HighlightedText></>}
        testimonials={testimonials}
      />


      <div id="pricing"/>
      <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          heading={
            <>
              Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
            </>
          }
          description="Choose the plan that aligns with your interview journey and amplify your chances of interview success. Interview CoPilot is here to support you through every step of the process."
          primaryButtonText="Subscribe"
          onPrimaryBtnAction = {async (priceName) => await handleSubscription(priceName)}
          plans={[
            {
              name: "Free",
              price: "$0.00",
              duration: "Monthly",
              mainFeature: "Recording Limit: 20 minutes /month",
              buttonText: "Get\u00A0Started",
              features: ["Getting a taste of real-time interview assistance", "Basic Assistance"]
            },
            {
              name: "Light",
              price: "$14.99",
              duration: "Monthly",
              mainFeature: "Recording Limit: 5 hours / month",
              features: ["If you are casually exploring new job opportunities and occasionally participating in interviews", "Basic Assistance"]
            },
            {
              name: "Basic",
              price: "$19.99",
              duration: "Monthly",
              mainFeature: "Recording Limit: 10 hours / month",
              features: ["if you have started actively searching for a new job and are in the early stages of interview processes", "Priority Assistance"],
              featured: true
            },
            {
              name: "Intensive",
              price: "$29.99",
              duration: "Monthly",
              mainFeature: "Recording Limit: 20 hours /month",
              features: ["If you are fully committed to finding your next career move and are consistently engaging in interviews and assessments", "Personal Assistance"]
            }
          ]}
      />

      <div id="faqs"/>
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText2>Questions ?</HighlightedText2>
          </>
        }
        description="And we have got answers to all of them."
        faqs={[
          {
            question: "How does Interview CoPilot work?",
            answer:
              "Interview CoPilot actively listens to your interviews in real-time. When you encounter a challenging question, simply tap the \"CoPilot\" button. Our AI analyzes the conversation, identifies the last question, and provides you with an expert response within seconds."
          },
          {
            question: "Is my interview data secure?",
            answer:
              "Absolutely. We prioritize your privacy and employ advanced encryption to keep your interview data confidential. Your information is only used to enhance your experience and provide tailored responses."
          },
          {
            question: "Can I use Interview CoPilot for remote interviews?",
            answer:
              "Absolutely! Interview CoPilot is specifically designed to assist you during remote interview formats, providing real-time support to ensure your success. Whether it's a virtual interview or any other remote setting, our platform is your trusted companion, helping you shine during these crucial moments."
          },
          {
            question: "How does the AI generate responses?",
            answer:
              "Our AI draws from a vast database of industry-specific knowledge and interview best practices. It takes into account your interview context, your responses, and the job role, providing you with responses that are both relevant and impressive."
          },
          {
            question: "Is using Interview CoPilot considered cheating?",
            answer:
              "Not at all. Interview CoPilot provides real-time assistance during your interviews, much like having a personal coach by your side. Think of it as guidance that empowers you to shine in the moment. It's about leveraging technology to showcase your expertise effectively and authentically."
          },
          {
            question: "Can I switch plans if my needs change?",
            answer:
              "Absolutely. We understand that your interview needs may evolve. You can easily switch between our subscription plans to find the one that best suits your requirements. Your interview success is our priority, and we're here to adapt to your journey."
          }
        ]}
      />


      <GetStarted text="Join Us and Seize Your Dream Job!" primaryLinkUrl="#pricing" secondaryLinkUrl="mailto:support@interviewcopilot.io" secondaryLinkText="Contact Us: support@interviewcopilot.io"/>
      <Footer />
    </AnimationRevealPage>
    </>
  );
}
