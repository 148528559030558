import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {LogoLink} from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import logo from "../images/logo.svg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <LogoLink href="/" style={{textDecoration: 'none'}}>
        <img src={logo} alt="logo" />
        Interview Copilot
      </LogoLink>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: October 2, 2023</p>

            <p>
              This Privacy Policy explains how Interview Copilot ("we", "us", "our") collects, uses, shares, and protects user ("you", "your") information when you visit and interact with our website, services, and software (collectively, "Services"). Please read this Privacy Policy carefully. By using our Services, you agree to the processing of your information in accordance with this Privacy Policy.
            </p>

            <h2>Information Collection</h2>
            <p>We may collect the following types of information:</p>
            <ul>
              <li>
                <p>
                  <strong>Personal Identification Information:</strong> We may collect personal identification information such as your email address and phone number when you register for our Services, or if you contact us directly.
                </p>
              </li>
              <li>
                <p>
                  <strong>Non-Personal Identification Information:</strong> We collect non-personal identification information such as your IP address, browser type, operating system, and other technical information about your means of connection to our Services.
                </p>
              </li>
              <li>
                <p>
                  <strong>Usage Data:</strong> We collect information about how you use our Services, including time spent on certain pages, the links you click, and other actions taken within our Services.
                </p>
              </li>
            </ul>


            <h2>How We Use Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
              <li>
                <p>
                  Provide, maintain, and improve our Services.
                </p>
              </li>
              <li>
                <p>
                  Respond to your comments, questions, and requests.
                </p>
              </li>
              <li>
                <p>
                  Conduct research and analysis for product development and improvements.
                </p>
              </li>
              <li>
                <p>
                  Send you technical notices, updates, security alerts, and administrative messages.
                </p>
              </li>
            </ul>

            <h2>Sharing of Information</h2>
            <p>We do not sell your personal information. We may share your information with third-party service providers to perform tasks on our behalf and to assist us in providing our Services. Such third parties will be obligated to maintain the confidentiality and security of your personal data.</p>

            <h2>Protection of Information</h2>
            <p>We use appropriate technical and organizational measures to protect the data we collect against unauthorized or unlawful processing and against accidental loss, destruction, or damage.</p>

            <h2>Your Rights</h2>
            <p>Depending on where you live, you may have certain rights in relation to your information such as access, rectification, restriction, opposition, portability, and deletion rights. Please contact us to exercise these rights.</p>

            <h2>Children's Privacy</h2>
            <p>Our Services are not directed at children under the age of 13. We do not knowingly collect personal information from children under 13.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We use appropriate technical and organizational measures to protect the data we collect against unauthorized or unlawful processing and against accidental loss, destruction, or damage.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at: support@interviewcopilot.io <br/><br/>
              By using our Services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
